import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"
import defaultImage from "../../assets/images/seo-logo.png"

const Seo = ({ title, description, image, article, jsonLd }) => {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(query)

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    twitterUsername,
  } = site.siteMetadata

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image:
      (jsonLd?.image && `https:${jsonLd?.image}`) ||
      `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
  }

  let jsonLdParsed = []
  if (jsonLd) {
    jsonLdParsed.push({
      "@context": "https://schema.org",
      "@graph": [
        {
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              name: "Blogs",
              item: "https://www.actuateminds.com/blog/",
            },
            {
              "@type": "ListItem",
              position: 2,
              name: jsonLd.category,
              item:
                "https://www.actuateminds.com/blog/" +
                jsonLd.category.toLowerCase() +
                "/",
            },
            {
              "@type": "ListItem",
              position: 3,
              name: title,
            },
          ],
        },
        {
          "@type": "Article",
          "@id": jsonLd.url,
          headline: title,
          description: jsonLd.description,
          image: {
            "@type": "ImageObject",
            url: seo.image,
          },
          datePublished: jsonLd.datePublished,
          author: {
            "@type": "Person",
            name: jsonLd.authorName,
          },
          publisher: {
            "@type": "Organization",
            name: "Actuate Minds",
            logo: {
              "@type": "ImageObject",
              url: `${siteUrl}${image || defaultImage}`,
            },
          },
        },
      ],
    })

    if (jsonLd.faq) {
      let mainEntry = []
      jsonLd.faq.forEach(faq => {
        mainEntry.push({
          "@type": "Question",
          name: faq.question,
          acceptedAnswer: {
            "@type": "Answer",
            text: faq.answer,
          },
        })
      })
      jsonLdParsed.push({
        "@context": "https://schema.org",
        "@type": "FAQPage",
        mainEntity: mainEntry,
      })
    }

    if (jsonLd.isQuestion) {
      jsonLdParsed.push({
        "@context": "https://schema.org",
        "@type": "QAPage",
        name: jsonLd.headline,
        mainEntity: {
          "@type": "Question",
          name: jsonLd.headline,
          answerCount: 1,
          dateCreated: jsonLd.datePublished,
          author: {
            "@type": "Person",
            name: jsonLd.authorName,
          },
          acceptedAnswer: {
            "@type": "Answer",
            text: jsonLd.description,
            dateCreated: jsonLd.datePublished,
            url: jsonLd.url,
            author: {
              "@type": "Person",
              name: jsonLd.authorName,
            },
          },
        },
      })
    }
  }
  return (
    <>
      <Helmet title={seo.title} titleTemplate={titleTemplate}>
        <link rel="sitemap" href="/sitemap/sitemap-0.xml" />

        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
        <meta
          name="ahrefs-site-verification"
          content="1a61fb252a002d927596edd17086d735cdcef4afa2d5dee4d83633622d0904b6"
        />

        <meta property="og:type" content="website" />
        {seo.url && <meta property="og:url" content={seo.url} />}

        {(article ? true : null) && (
          <meta property="og:type" content="article" />
        )}

        {seo.title && <meta property="og:title" content={seo.title} />}

        {seo.description && (
          <meta property="og:description" content={seo.description} />
        )}

        {seo.image && <meta property="og:image" content={seo.image} />}

        <meta name="twitter:card" content="summary_large_image" />

        {twitterUsername && (
          <meta name="twitter:creator" content={twitterUsername} />
        )}

        {seo.title && <meta name="twitter:title" content={seo.title} />}

        {seo.description && (
          <meta name="twitter:description" content={seo.description} />
        )}

        {seo.image && <meta name="twitter:image" content={seo.image} />}

        {jsonLd &&
          jsonLdParsed.map((block, i) => (
            <script key={i} type="application/ld+json">
              {`
              ${JSON.stringify(block, null, 2)}
            `}
            </script>
          ))}
      </Helmet>
    </>
  )
}

export default Seo

Seo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
}

Seo.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
}

const query = graphql`
  query Seo {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl
      }
    }
  }
`
